// global styles
@use '@angular/material' as mat;
a { cursor: pointer; }

.app-container {
    min-height: 320px;
}

.admin-nav {
    padding-top: 0;
    padding-bottom: 0;
    background-color: #e8e9ea;
    border-bottom: 1px solid #ccc;
}

.btn-delete-account {
    width: 40px;
    text-align: center;
    box-sizing: content-box;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
